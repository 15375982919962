.home {
  min-height: 100vh;
  height: 100vh;
  margin-bottom: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.description {
  font-size: 1.1vw;;
  font-style: italic;
  font-weight: 50;
}

.home_title{
  /* font-size: 4.4rem; */
  font-size: 4.1vw;
  font-weight: 700;
  margin-bottom: 3.2rem;
  text-align: left;
  line-height: 0.99em;
  margin:0;
  padding-top:20%;
}
.text-color-main {
  color: goldenrod
}
.home_social {
  /* display: block; */
  padding: 0;
}

.home_social-icon {
  font-size: 1.5rem;
  color: var(--title-color);
}

@media screen and (max-width: 971px) {
  .home_title {
    font-size: 6.4vw;
    font-weight: 500;
    margin-bottom: 2.2rem;
    text-align: left;
    margin:0;
    padding-top: 0;
  }
  .description {
    font-size: 2.0vw;
  }
}

@media screen and (max-width: 690px) {
  .home_title {
    font-size: 5.5vw;
    font-weight: 100;
    margin-bottom: 1rem;
    text-align: left;
    margin:0;
    padding-top: 0;
  }
  .description {
    font-size: 2.5vw;
  }
}

@media screen and (max-width: 390px) {
  .home_title {
    font-size: 6.5vw;
    font-weight: 100;
    margin-bottom: 1rem;
    text-align: left;
    margin:0;
    padding-top: 0;
  }
  .description {
    font-size: 3.1vw;
  }
}

